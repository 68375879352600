window.handleLogEvent = (fromRails) => {
  if (fromRails) {
    var eventData = fromRails;
    var eventName = fromRails.event_name;
    delete eventData.event_name;
    document.addEventListener('turbolinks:load', function (event) {
      if(typeof(gtag) === 'function'){
        gtag('event', eventName, eventData);
      }
    });
  }
};
